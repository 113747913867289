@import "~@/styles/variables";

































































































































































































































































.pharmacy-types {
  border-radius: $border-radius;

  ::v-deep {
    label.btn {
      padding: 0.75rem 1.3rem !important;
      font-size: 1.1em;
      font-weight: $f-weight-book;
      text-transform: capitalize;
    }
  }
}
.zipcode-input {
  font-size: 18px;
  width: 7.5em;
}
.add-manually {
  background-color: $pp-snowball;
}

.pharmacy-address {
  line-height: 1.2;
}
